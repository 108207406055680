// extracted by mini-css-extract-plugin
export var cloudAppDevBenefitsSection = "N_hZ";
export var cloudAppDevBusinessSection = "N_hT";
export var cloudAppDevCasesSection = "N_h1";
export var cloudAppDevIndustriesSection = "N_hV";
export var cloudAppDevModelSection = "N_hS";
export var cloudAppDevOurAchievementsSection = "N_hY";
export var cloudAppDevPrimeSection = "N_hQ";
export var cloudAppDevProcessSection = "N_hW";
export var cloudAppDevProjLogoSection = "N_h0";
export var cloudAppDevQuotesSection = "N_h2";
export var cloudAppDevServicesSection = "N_hR";
export var cloudAppDevTechStackSection = "N_hX";